@include mobile(){
	.contact-index-index{
		#contact-form{
			legend, 
			.actions-toolbar{
				margin-left: 0;
			}
		}
	}
}

@include desktop(){
	.contact-index-index{
		#contact-form, 
		.page-title-wrapper{
			max-width: 900px;
			margin: 0 auto;
		}

		#contact-form{
			.fieldset{
				.name, 
				.email{
					width: 50%;
					float: left;
				}

				.name{
					padding-right: 1rem;
				}

				.email{
					padding-left: 1rem;
				}
			}
		}
	}
}