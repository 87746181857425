@include mobile(){

	.checkout-container {
		.authentication-wrapper {
			display: none;
		}
	}
	
	.opc-wrapper {

		.fieldset {
			.control {
				position: relative;
			}

			.payment-method {
				[type="radio"] {
					position: absolute;
					left: -9999px;
					opacity: 0;
				}
			}

			.tig_buckaroo_ideal .payment-method-second-col, .tig_buckaroo_creditcard .payment-method-second-col {

				legend {
					float: none;
					width: 100%;
					text-align: left;
				}

				.control {
					margin: 0.5em 0;
					width: 100%;
					float: none;
					position: relative;
				}

				[type="radio"]:checked, [type="radio"]:not(:checked) {
					position: absolute;
					left: -9999px;
					opacity: 0;
				}

				.bank-types-img, .card-types-img {
					position: absolute;
					left: 30px;
				}

				.bank-types, .card-types {
					padding-left: 65px;
					float: none;
				}
			}
		}

		form.form-login {
			margin: 15px 0;
			padding: 0 0 15px;

			.fieldset {
				.actions-toolbar {
					width: 74.2%;
					margin-left: 0;
				}
			}
		}

		.shipping-address-item {
			margin: 0 0 15px;
			padding: 15px 35px 15px 18px;
			width: 100%;

			&.selected-item {
				padding: 15px 35px 15px 18px;
				border-bottom-width: 2px;

				.edit-address-link{
					right: 39px;
				}
			}
		}

		.form-login, .form-shipping-address {
			margin-top: 28px
		}

		.action-select-shipping-item {
			float: none;
			margin-top: 10px;
			width: 100%;
		}

		.action-show-popup {
			width: 100%;
		}

		.edit-address-link {
			display: inline-block;
			text-decoration: none;
			margin: 0;
			position: absolute;
			right: 0;
			top: 1px;

			&:before {
				-webkit-font-smoothing: antialiased;
				font-size: 28px;
				line-height: 28px;
				color: #303030;
				content: '\e606';
				font-family: 'icons-blank-theme';
				vertical-align: middle;
				display: inline-block;
				font-weight: normal;
				overflow: hidden;
				speak: none;
				text-align: center;
			}

			&:hover:before,
			&:active:before {
				color: #303030;
			}
		}

		.tig_buckaroo_ideal .payment-method-second-col, .table-checkout-shipping-method, .tig_buckaroo_creditcard .card-types {
			[type="radio"]:not(:checked),
			[type="radio"]:checked {
				position: relative;
				left: 0;
				opacity: 1;
			}
		}


		.payment-method {
			.tig_buckaroo_image_title {
				&::after {
					width: 16px;
					height: 16px;
					background: $radio-fill-color;
				}
			}
		}

		.fieldset > .field:not(.choice).street > legend.label {
			display:none
		}

		.checkout-shipping-method .step-title {
			border: 0;
		}


		.table-checkout-shipping-method {

			.row {
				cursor: pointer;
			}

			.row .col {
				padding:.5rem;
				float:none;
			}

			[type="radio"]:not(:checked),
			[type="radio"]:checked {
				position: absolute;
				left: -9999px;
				opacity: 0;
			}
		}
	}

	.table-checkout-shipping-method {
		thead{
			border: none;
		}

		label {
			top: 0;
		}
	}

	.opc-progress-bar-item {
		& > span::after{
			font-size: 1.2rem;
		}

		&._active > span::after, &._complete > span::after {
			font-size: 1.8rem
		}
	}

	.checkout-payment-method .step-title {
		padding: 0 15px;
	}

	.opc-progress-bar {
		margin-top: 1em
	}

	.checkout-onepage-success{
		.print{
			display: none;
		}
	}

	.checkout-index-index {
		.nav-sections, 
		.nav-toggle {
			display: block;
		}
	}
}

@include desktop() {
	#opc-new-shipping-address {
		margin-bottom: 2em;

		.fieldset {
			& > .field {
				&:not(.choice) > .label {
					font-weight: normal;
					display: block;
					width: 100%;
					float: none;
					text-align: left;
				}

				&.choice:last-child {
					&::before {
						display: none;
					}
				}
			}
		}
	}
}

@include desktop() {
    .form-shipping-address,
	.billing-address-form,
    .form-login {
        margin-bottom: 2em;
        .fieldset {
            & > .field {
                &:not(.choice) > .control {
                    float: none !important;
                    width: 100% !important;
                }
                &:not(.choice) > .label {
                    width: auto !important;
                    height: auto !important;
                    clip: auto !important;
                }
            }
        }
		.field-tooltip.toggle{
			.label {
				display: none;
			}
		}
    }
	.billing-address-form {
		.fieldset {
            & > .field.tig_postcode_nl {
				max-width: 100%!important;
			}
		}
	}
}


.opc-wrapper {
    .fieldset {
        > .field {
            &._required {
                .label {
					width: auto;
					height: auto;
					clip: auto;
                    &::after {
                        content  : '*';
                        color    : #e02b27;
                        font-size: 1.2rem;
                        margin   : 0 0 0 5px;
                    }
                }
            }
        }
    }
}

fieldset{
    .control{
        .field {
            &._required {
                .label {
					width: auto;
					height: auto;
					clip: auto;
                    &::after {
                        content  : '*';
                        color    : #e02b27;
                        font-size: 1.2rem;
                        margin   : 0 0 0 5px;
                    }
                }
            }
        }
    }
}

