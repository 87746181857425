@import "_var";

@mixin flexSidebar(){
	position: absolute;
	max-width: 100%;
	left: -$flex-sidebar-width !important;
	overflow: visible !important;
	background: #efefef;
	z-index: 1000;
	width: $flex-sidebar-width !important;
	transition: 0.25s ease-in all;
	border: 1px solid #ccc;
	padding: 1em;


	.flexSidebar-arrow {
		background: #efefef;
		border: 1px solid #ccc;
		display: block;
		position: absolute;
		top: 0;
		left: 100%;
		transition: 0.3s ease-in all;
		z-index: 1000;
		overflow: hidden;
		padding:.5rem 1rem;

		.open {display:block;}
		.close {display:none;}

		i {
			font-size:2em;
		}
	}

	&.active {
		left: 0 !important;
		.flexSidebar-arrow {
			.open {display:none;}
			.close {display:block;}
		}
	}

	& > div {
		overflow: hidden;
	}
}

.flexSidebar {
	@include flexSidebar();
}

@media (max-width: 767px) {
	.flexSidebarMobile {
		@include flexSidebar();
	}
}

@media (min-width: 768px) {
	.flexSidebarMobile {
		.flexSidebar-arrow {
			display: none;
		}
	}
}
