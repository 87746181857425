@include mobile(){

	#switcher-currency-nav {
		.label.switcher-label, .actions.dropdown.options.switcher-options {
			color: $text-top-links;
		}
	}
	.sections.nav-sections {

		a {
			color: $text-top-links;
		}
	}

	.header-banner-wrap {
		display: none;
	}
	.page-header{
		border-bottom: 1px solid lighten($c-border-primary, 2%);
		margin-bottom: 20px;

		.action.nav-toggle {
				&::before {
					color: $text-top-links;
				}
		}

		.logo {
			display: block;
			margin: 0;
			float: none;
			margin-left: auto;
			margin-right: auto;
		}

		.header.content {

			.minicart-wrapper {
				.action.showcart {
					&::before {
						color: $text-top-links;
					}
				}
			}

			.block.block-search {
					.block.block-content {
						.form.minisearch {
							.field.search {

								.label {
									&::before {
										color: $text-top-links;
									}
								}

								.control {
									.input-text, .input-text:focus, .input-text:active {
										color: $text-top-links;
										border-color: $text-top-links;
									}
								}
							}
							.actions {
								.action.search {
									&::before {
										color: $text-top-links;
									}
								}
							}
						}
					}
				}

			.panel.wrapper {
				.panel.header {
					.header.links {
						li {
							margin: 0;
							a {
								color: $text-top-links;
								text-decoration: none;
								font-weight: $text-weight-light;
							}
							&::before {
								content: "|";
								margin-left: 0.5rem;
								margin-right: 0.5rem;
								color: $text-top-links;
								font-weight: $text-weight-light;
							}
						}
						.nav.item {
							&::before {
								content: "";
								margin-left: 0;
								margin-right: 0;
							}
						}
					}
				}
			}
		}

		.switcher{
			margin-right: 10px;

			.options{
				display: inline-block;
				position: relative;

				&:before, &:after{
					content: '';
					display: table;
				}

				&:after{
					clear: both;
				}

				.action.toggle{
					padding: 0;
					cursor: pointer;
					display: inline-block;
					text-decoration: none;

					&:after{
						-webkit-font-smoothing: antialiased;
						font-size: 22px;
						line-height: 22px;
						color: inherit;
						content: '\e622';
						font-family: 'icons-blank-theme';
						margin: 0;
						vertical-align: top;
						display: inline-block;
						font-weight: normal;
						overflow: hidden;
						speak: none;
						text-align: center;
					}

					.active{
						display: inline-block;
						text-decoration: none;
						overflow: visible;

						:after{
							-webkit-font-smoothing: antialiased;
							font-size: 22px;
							line-height: 22px;
							color: inherit;
							content: '\e621';
							font-family: 'icons-blank-theme';
							margin: 0;
							vertical-align: top;
							display: inline-block;
							font-weight: normal;
							overflow: hidden;
							speak: none;
							text-align: center;
						}
					}

					ul.dropdown{
						li{
							margin: 0;
							padding: 0;

							&:hover{
								background: darken($c-nav-bg-primary, 5%);
								cursor: pointer;
							}

							&:before, &:after{
								border-bottom-style: solid;
								content: '';
								display: block;
								height: 0;
								position: absolute;
								width: 0;
							}

							&:before{
								border: 6px solid;
								border-color: transparent transparent #ffffff transparent;
								z-index: 99;
								left: 10px;
								top: -12px;
							}

							&:after{
								border: 7px solid;
								border-color: transparent transparent #bbbbbb transparent;
								z-index: 98;
								left: 9px;
								top: -14px;
							}
						}
					}
				}
			}

			li{
				font-size: 12px;
				margin: 0;
			}

			strong{
				font-weight: 400;
			}
		}

		.header.content{
			padding: $header-content-padding;
			position: relative;
		}

		.message.global{
			p{
				margin: 0;
			}

			.noscript, .cookie{
				margin: 0 0 10px;
				padding: 10px 20px;
				display: block;
				line-height: 1.2em;
				font-size: 1.3rem;
				background: #ffee9c;
				border-color: #d6ca8e;
				color: $c-text-primary-black;
				margin: 0;

				a{
					color: $c-anchor;

					&:hover,{
						color: $c-anchor-hover;
					}

					&:active{
						color: $c-anchor-active;
					}
				}
			}
		}
	}

	.nav-sections{
		-webkit-overflow-scrolling: touch;
		-webkit-transition: left 0.3s;
		-moz-transition: left 0.3s;
		-ms-transition: left 0.3s;
		transition: left 0.3s;
		height: 100%;
		left: -80%;
		left: calc(-1 * (100% - 54px));
		overflow: auto;
		position: fixed;
		top: 0;
		width: 80%;
		width: calc(100% - 54px);

		.switcher{
			border-top: 1px solid lighten($c-border-primary, 2%);
			font-size: 1.6rem;
			font-weight: 700;
			margin: 0;
			padding: .8rem 3.5rem .8rem 2rem;

			&:last-child{
				border-bottom: 1px solid lighten($c-border-primary, 2%);
			}
		}

		.header.links{
			margin: 0;
			padding: 0;
			list-style: none none;
			border-bottom: 1px solid lighten($c-border-primary, 2%);

			li{
				font-size: 1.6rem;
				margin: 0;

				&.greet.welcome{
					border-top: 1px solid lighten($c-border-primary, 2%);
					font-weight: 700;
					padding: 0.8rem 20px;
					display: none;
				}

				& > a{
					border-top: 1px solid lighten($c-border-primary, 2%);
				}
			}

			a, a:hover{
				color: $text-top-links;
				text-decoration: none;
				display: block;
				font-weight: 700;
				padding: 0.8rem 20px;
			}
		}
	}

	.nav-sections-item-title{
		background: darken($c-nav-bg-primary, 5%);
		border: solid #dbdbdb;
		border-width: 0 0 1px 1px;
		box-sizing: border-box;
		float: left;
		height: 71px;
		padding-top: 24px;
		text-align: center;
		width: 33.33%;

		&.active{
			background: transparent;
			border-bottom: 0;
		}
	}

	.navigation{
		background: $c-nav-bg-primary;
		box-sizing: border-box;

		li{
			margin: 0;

			&.level0:last-child{
				border-bottom: 1px solid lighten($c-border-primary, 2%);
			}
		}

		a, a:hover{
			color: lighten($c-text-primary-black, 15%);
			text-decoration: none;
		}

		.level0{
			border-top: 1px solid lighten($c-border-primary, 2%);
			font-size: 1.6rem;

			& > .level-top{
				font-weight: 700;
				padding: 8px 40px 8px 15px;
				text-transform: uppercase;
				word-wrap: break-word;
			}

			&.active {
				.all-category .ui-state-focus{
					border-color: $c-anchor-active;
					border-style: solid;
					border-width: 0 0 0 8px;
					padding-left: 7px;
					display: inline-block;
				}

				& > a:not(.ui-state-active){
					border-color: $c-anchor-active;
					border-style: solid;
					border-width: 0 0 0 8px;

					span:not(.ui-menu-icon){
						margin-left: -8px;
					}
				}
			}

			&.has-active > a:not(.ui-state-active){
				border-color: $c-anchor-active;
				border-style: solid;
				border-width: 0 0 0 8px;

				span:not(.ui-menu-icon){
					margin-left: -8px;
				}
			}

			& > .level-1{
				font-weight: 600;
			}
		}

		.submenu:not(:first-child){
			ul > li a{
				color: $c-anchor;
				display: block;
				line-height: normal;
			}

			.active > a{
				border-color: $c-anchor-active;
				border-style: solid;
				border-width: 0 0 0 8px;
				padding-left: 7px;
			}
		}
	}

	.field.search{
		input[type="text"]{
			height: 32px;
		}
	}

	.minicart-wrapper{
		margin-top: 1.4em;
	}
}

@include desktop(){
	.page-header {
		max-width: 1280px;
		margin: 0 auto;
		display: block;
		width: 100%;
		position: relative;

		.header.content {
			margin-top: 1rem;
			margin-bottom: 1rem;

			.action.nav-toggle {
				display: none;
			}

			.panel.wrapper {
				.panel.header {
					padding-bottom: 0;
				}
			}

			.minicart-wrapper, .block.block-search {
				margin-top: 0;
			}

			.block.block-search {
				.field.search {
					.control {
						padding-bottom: 0;
					}
				}
			}
		}

		.logo {
			position: absolute;
			left: 0;
			top: -4rem;
			z-index: 10;
		}
	}

	.header-banner-wrap {
		display: block;
		max-width: 1280px;
		margin-left: auto;
		margin-right: auto;

		img {
			display: block;
			width: 100%;
		}
	}

	@media(max-width: 992px) {
		.page-header {
			.logo {
			top: -1rem;
				left: 0;
				max-width: 250px;
			}
		}
	}

	@media(max-width: 810px) {
		.page-header {
			.logo {
				max-width: 220px;
				top: -0.3rem;
			}
		}
	}
}
