@include mobile(){
	.catalog-product-view {

		.page-title-wrapper {
			display: none;
		}

		.breadcrumbs {
			margin-top: 1rem;
			margin-bottom: 1rem;

			.items {
				.item {

					a, strong {
						color: $text-product-breadcrumbs;
						font-weight: $text-weight-light;
					}
				}
			}

			.back-to-overview {
				display: none;
			}
		}

		.columns {
			.product.media {
				@media(max-width: 767px){
					width: 100%;
					order: 0;
				}

				.fotorama__thumb.fotorama_vertical_ratio {
					background-color: transparent;
				}

				.fotorama__thumb-border {
					background-image: none;
					border: 2px solid $text-active-category;
				}

				.fotorama__img {
					border: 1px solid $text-heading-categories !important;
					background-color: transparent;
					width: 90%;
					margin-left: 5%;
					cursor: pointer;

				}

				.fotorama__stage__shaft.fotorama__grab {
					border: 1px solid $text-heading-categories !important;
					max-width: 700px;

					.fotorama__stage__frame.fotorama__active.fotorama_vertical_ratio {
						.fotorama__img {
							border: none !important;
							width: auto;
							margin-left: 0;
						}
					}
				}

				.fotorama-item:not(.fotorama--fullscreen) {
					.fotorama__stage__shaft .fotorama__stage__frame::after	{
						font-family: FontAwesome;
						font-size: 40px;
						color: white;
						content: "\f00e";
						width: 70px;
						height: 60px;
						position: absolute;
						background: $text-active-category;
						bottom: 0;
						right: 0;
						display: block;
					}
				}
			}

			.column.main {
				display: inline;

				.product.attribute.overview {
					display: none;
				}

				.product-info-main {
					position: relative;
					order: 1;

					.price-box.price-tier_price {
						display: none;
					}

					.stock.available {
						color: $text-heading-categories;
						text-transform: initial;
						font-weight: 400;
						margin-top: 2rem;

						& > span {
							display: none;
						}
					}

					.product-info-price {
						border-bottom: none;

						.type {
							display: none;
						}

						.value {
							font-size: 1.4rem;
							font-weight: $text-weight-bold;
							color: #D37030;
						}

						.price-box.price-final_price {
							margin-top: 0;

							.price-including-tax{
								display: none;
							}
						}
					}

					.product.attribute.description {
						.type {
							display: none;
						}

						.value {
							color: $text-product;
							text-align: justify;
						}
					}

					.product-info-stock-sku {
						.stock.available {

							.label {
								color: $text-heading-categories;
								font-size: 1.2rem;
								text-transform: none;
							}

							span:last-child {
								font-weight: $text-weight-normal;
								color: $text-heading-categories;
							}
						}

						.product.attribute.sku {
							color: $text-heading-categories;
							display: block;
							margin-bottom: 2rem;
						}
					}

					.product-add-form {
						.box-tocart {
							width: 100%;

							.fieldset {
								.additional-attributes-wrapper{
									color: $text-heading-categories;

									.attributes-heading{
										display: inline-block;

										span {
											font-weight: $text-weight-bold;
											font-size: 1.2rem;
											float: left;
										}

										i {
											font-size: 1.8rem;
											color: $text-active-category;
											margin-left: 0.5rem;
										}

										&:hover {
											cursor: pointer;
										}
									}
								}

								.field.qty {
									clear: both;
									margin-bottom: 0;
									padding-right: 0;

									.control {
										.label.active {
											font-size: 1.2rem;
											float: left;
											color: $text-heading-categories;
											margin-right: 1rem;
										}

										.input-text.qty {
											height: 2rem;
											width: 3rem;
											border: 1px solid $text-heading-categories;
										}
									}
								}

								.actions {
									clear: both;
									display: block;
									text-align: left;

									.action.primary.tocart {
										padding: 0;
										padding: 7px 15px;
										font-size: 1.4rem;
										line-height: 2rem;
										margin-left: 1%;
									}

									.action.primary.checkout, .action.primary.tocart {
										display: block;
										max-width: 400px;
										width: 100%;
										margin-bottom: 2rem;
										margin-left: auto;
										margin-right: auto;
										text-align: center;
										background-color: $categories-background-hover;
										font-weight: $text-weight-bold;
										transition: none;
										border-radius: 0;

										&:hover,
										&:focus,
										&:active {
											background-color: $text-active-category;
											border-color: $text-active-category;
										}
									}
								}
							}
						}

						#product-options-wrapper{
							position: relative;

							.field.configurable.required {
								float: left;

								.label {
									display: inline;
								}

								.control {
									display: inline-block;
								}
							}

							.additional-attributes-wrapper{
								color: $text-heading-categories;

								.attributes-heading{
									display: inline-block;

									span {
										font-weight: $text-weight-bold;
										font-size: 1.2rem;
										float: left;
									}

									i {
										font-size: 1.8rem;
										color: $text-active-category;
										margin-left: 0.5rem;
									}

									&:hover {
										cursor: pointer;
									}
								}
							}
						}
					}
				}
			}

			.block.related {
				order: 2;

				.block-title.title {
					text-align: center;
				}

				.block-content.content {
					.block-actions {
						display: none;
					}

					.products-related {
						.product-items {
							.product-item {
								text-align: center;
								margin-bottom: 2rem;

								.product-item-info {
									width: 100%;

									.product-image-photo {
										border: 1px solid $text-heading-categories;
									}

									.product-item-details {
										.price-box.price-final_price, .field.choice.related, .product-item-actions, .product-item-name {
											display: none;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.product-item .price-box .price-label{
		color: #7d7d7d;
		font-size: 12px;
	}

	.toolbar{
		margin-bottom: 30px;
		text-align: center;

		select{
			background-color: darken($c-form-bg-primary, 5%);
			box-shadow: inset 0 1px 0 0 #ffffff, inset 0 -1px 0 0 rgba(204, 204, 204, 0.3);
			border-radius: 3px;
		}
	}

	.product-info-main .product-info-price{
		color: #575757;
		border-bottom: 1px solid $c-border-primary;
		display: table;
		margin-bottom: 15px;
		width: 100%;

		.product-name{
			display: inline-block;
		}

		.price-including-tax + .price-excluding-tax,
		.weee + .price-excluding-tax,
		.weee{
			.price{
				font-size: 1.4rem;
				font-weight: 600;
				line-height: 16px;
			}
		}

		.price-wrapper .price{
			font-size: 2.2rem;
			font-weight: 600;
			line-height: 22px;
		}
	}

	.product-options-bottom .price-box,
	.product-info-price .price-box{
		color: #575757;
		display: inline-block;
		padding-bottom: 0;
		vertical-align: top;
		float: right;

		.old-price{
			font-size: 20px;
			font-weight: 300;

			.price-container > span{
				display: inline-block;
			}
		}

		.price-container > span{
			display: block;

			&:last-child{
				margin-bottom: 0;
			}
		}

		.price-container .price{
			font-size: 22px;
			font-weight: 600;
			line-height: 22px;
		}
	}

	.table.grouped{
		& > thead, & > tbody, & > tfoot{
			& > tr > th, & > tr > td{
				border-top: 1px solid $c-border-primary;
			}
		}
		& > caption + thead, & > colgroup + thead, & > thead:first-child{
			& > tr:first-child > th, & > tr:first-child > td{
				border-top: 0;
			}
		}
		& > tbody + tbody{
			border-top: 1px solid $c-border-primary;
		}
		& > thead > tr > th{
			border-bottom: 0;
		}
		.product-item-name{
			font-weight: 400;
		}
		.price-box .price{
			font-size: 14px;
		}
	}

	.wishlist.window.popup{
		box-sizing: border-box;
		background: $c-background-primary;
		border: 1px solid $c-border-primary;
		padding: 22px;
		width: 100%;
		box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
		transition: opacity 0.3s linear;
		bottom: 0;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 1001;
		display: none;
		opacity: 0;

		.popup-header{
			margin: 0 0 25px;
			padding-right: 22px;

			.title{
				font-weight: 300;
				line-height: 1.1;
				font-size: 1.8rem;
				margin-top: 1.5rem;
				margin-bottom: 1rem;
			}
		}

		.popup-actions{
			.actions.close{
				position: absolute;
				display: inline-block;
				background-image: none;
				background: none;
				-moz-box-sizing: content-box;
				border: 0;
				box-shadow: none;
				line-height: inherit;
				margin: 0;
				padding: 0;
				text-decoration: none;
				text-shadow: none;
				font-weight: 400;
				right: 10px;
				top: 10px;

				&:before{
					webkit-font-smoothing: antialiased;
					font-size: 22px;
					line-height: 22px;
					color: inherit;
					content: '\e616';
					font-family: 'icons-blank-theme';
					margin: 0;
					vertical-align: top;
					display: inline-block;
					font-weight: normal;
					overflow: hidden;
					speak: none;
					text-align: center;
				}
			}
		}
	}

	.products-grid.wishlist .product-item{
		border-bottom: 1px solid $c-border-primary;

		&:before{
			border-bottom: 1px solid $c-border-primary;
		}
	}

	.product.data.items{
		& > .item.title > .switch{
			background: darken($c-background-primary, 5%);
			border-bottom: 1px solid $c-border-primary;
			border-left: 1px solid $c-border-primary;
			border-right: 1px solid $c-border-primary;
			border-top: 1px solid $c-border-primary;
			height: 40px;
			padding: 5px 20px 5px 20px;
			font-weight: 600;
			line-height: 40px;
			font-size: 1.8rem;
			color: #7d7d7d;
			text-decoration: none;

			&:visited, &:hover{
				color: #7d7d7d;
				text-decoration: none;
			}

			&:active{
				color: #333333;
				text-decoration: none;
			}
		}

		& > .item.title:not(.disabled) > .switch{
			&:focus, &:hover{
				background: #fcfcfc;
			}

			&:active{
				background: $c-background-primary;
				padding-bottom: 5px;
			}
		}

		& > .item.title.active > .switch{
			background: $c-background-primary;
			padding-bottom: 5px;

			&:focus, &:hover{
				background: $c-background-primary;
				padding-bottom: 5px;
			}
		}

		& > .item.content{
			background: $c-background-primary;
			border: 1px solid $c-border-primary;
			margin: 0 0 5px;
			padding: 20px 20px 20px 20px;
		}
	}

	.products-grid{
		.product-item{
			@media(min-width: 992px){
				width: 33.333333%
			}
			@media(max-width: 991px){
				width: 50%
			}
			@media(max-width: 600px){
				width: 100%;
			}
		}
	}

	.page-products,
	.page-layout-1column,
	.page-layout-3columns,
	.page-products.page-layout-1column,
	.page-products.page-layout-3columns{
		.products-grid{
			.product-item{
				@media(min-width: 992px){
					width: 33.333333%
				}
				@media(max-width: 991px){
					width: 50%
				}
				@media(max-width: 600px){
					width: 100%;
				}
			}
		}
	}
}

@include desktop(){
	.catalog-product-view {

		.breadcrumbs {
			.items {
				display: inline-block;
			}

			.back-to-overview {
				display: inline-block;
				float: right;
				color: $text-product-breadcrumbs;
				text-decoration: none;
				font-size: 1.2rem;
				font-weight: $text-weight-light;

				&:hover {
					cursor: pointer;
				}
			}
		}

		.columns {

			.column.main {

				.product-info-main {

					.product-add-form {
						.box-tocart {

							.fieldset {

								.field.qty {
									@media(min-width: 1100px) {
										float: right;
										clear: none;
									}
								}

								.actions {
									@media(min-width: 1100px) {
										.action.primary.checkout, .action.primary.tocart {
											display: inline-block;
											width: 49%;
											max-width: none;
										}
									}
								}
							}
						}

						#product-options-wrapper{


							.field.configurable.required {
								float: none;
								@media(min-width: 1100px) {
									position: absolute;
								}

								.label, .control {
									display: inline-block;
								}
							}
						}
					}
				}
			}
			.block.related {
				width: 57%;

				.block-title.title {
					text-align: left;
				}

				.block-content.content {

					.products-related {
						.product-items {
							.product-item {
								width: 32%;
								margin-left: 1.5%;
								margin-bottom: 0;

								&:nth-child(3n) {
									margin-left: 1%;

									.product-item-info {
										margin-left: 2.5%;
									}
								}

								.product-item-info {
									margin-left: 0.5%;
									width: 95%;
								}
							}
						}
					}
				}
			}
		}
	}
}
