
@mixin h1() {
	font-size: 1.2em;
	color: $c-text-primary-black;
	border-bottom: 1px solid darken($c-text-primary-white, 20%);
}

@mixin link() {
	color: $c-anchor;
	text-decoration: none;

	&:visited {
		color: $c-anchor-visited;
		text-decoration: none;
	}

	&:active{
		color: $c-anchor-active;
		text-decoration: underline;
	}

	&:hover{
		color: $c-anchor-hover;
		text-decoration: underline;
	}
}

@mixin tooltip() {
	background: #ffffff;
	max-width: 360px;
	min-width: 210px;
	padding: 12px 16px;
	z-index: 100;
	display: none;
	position: absolute;
	text-align: left;
	color: #333333;
	line-height: 1.4;
	border: 1px solid #bbbbbb;
	margin-top: 5px;
	left: 0;
	top: 100%;

	.subtitle{
		display: inline-block;
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 15px;
	}

	&:before, &:after{
		border: solid transparent;
		content: '';
		height: 0;
		position: absolute;
		width: 0;
		bottom: 100%;
	}

	&:before{
		border-width: 5px;
		border-color: transparent;
		border-bottom-color: #bbbbbb;
		left: 15px;
		margin-left: -6px;
	}

	&:after{
		border-width: 6px;
		border-color: transparent;
		border-bottom-color: #ffffff;
		left: 15px;
		margin-left: -5px;
	}
}