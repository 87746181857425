@include mobile(){

	.customer-account-index {

		.page-title-wrapper {
			text-align: center;
		}

		.columns {

			.sidebar-wrapper {
				max-width: 70%;
				width: 100%;
				margin-left: auto;
				margin-right: auto;
			}

			.column.main {
				max-width: 70%;
				margin-left: auto;
				margin-right: auto;

				.box-actions {
					a {
						text-decoration: underline;
					}
				}
			}
		}
	}

	.customer-account-login, .customer-account-create, .customer-account-forgotpassword, .customer-account-index {
		.page-title-wrapper {
			.page-title {
				color: $text-product;
			}
		}
	}

	.login-container{
		.fieldset:after{
			margin: 35px 0 0;
			content: attr(data-hasrequired);
			display: block;
			letter-spacing: normal;
			word-spacing: normal;
			color: #e02b27;
			font-size: 1.2rem;
		}
	}

	.account-nav{
		.content{
			background: $c-nav-bg-primary;
			padding: 15px 0;
		}

		.item{
			margin: 3px 0 0;

			& > strong{
				border-left: 3px solid transparent;
				display: block;
				padding: 5px 18px 5px 15px;
			}
			a{
				border-left: 3px solid transparent;
				display: block;
				padding: 5px 18px 5px 15px;
				text-decoration: none;
			}

			.current{
				a, strong{
					border-color: #ff5501;
					font-weight: 600;
				}
			}
		}
	}

	.account .table-wrapper .data.table.wishlist{
		& > thead, & > tbody, & > tfoot{
			& > tr{
				& > th, & > td{
					border-top: 1px solid lighten($c-border-primary, 5%);
				}
			}
		}

		& > caption + thead,
		& > colgroup + thead,
		& > thead:first-child{
			& > tr:first-child{
				& > th, & > td{
					border-top: 0;
				}
			}
		}

		& > tbody + tbody{
			border-top: 1px solid lighten($c-border-primary, 5%);
		}

		thead{
			tr > th{
				border-bottom: 0;
			}
		}

		tbody{
			& > tr:last-child > td{
				border-bottom: 1px solid lighten($c-border-primary, 5%);
			}
		}
	}
}

@include desktop(){

	.customer-account-index {

		.page-title-wrapper {
			text-align: left;
		}

		.columns {

			.sidebar-wrapper {
				max-width: none;
				width: auto;
				margin-left: 0;
				margin-right: 0;
			}

			.column.main {
				max-width: none;
				margin-left: 0;
				margin-right: 0;
			}
		}
	}

	.customer-account-login, .customer-account-create, .customer-account-forgotpassword {

		.page {
			.messages {
				width: 450px;
				margin: 0 auto;
			}
		}

		.page-title-wrapper {
			text-align: center;
			margin-bottom: 40px;

			.page-title {
				font-size: 1.1em;
				border: 0;
				margin-bottom: 0;
			}
		}
	}

	.customer-account-login {

		.column.main {
			.login-container {
				.fieldset {
					&::after {
						margin-left: 0;
					}

					.actions-toolbar {
						margin-left: 0;
					}
				}

				.block-customer-login {
					width: 600px;
					margin: 0 auto;
					float: none;
				}
			}
		}
	}

	.customer-account-create, .customer-account-forgotpassword {

		.column.main {
			form {
				width: 600px;
				margin: 0 auto;
				text-align: center;

				.fieldset {
					.legend {
						margin: 0 auto;
						display: block;
						width: 100%;
					}

					.field {
						&:not(.choice) > .control {
							float: none;
							margin-left: auto;
							margin-right: auto;
						}
					}

				}

				.actions-toolbar {
					width: 74.2%;
					margin-left: auto;
					margin-right: auto;
				}

			}
		}

	}

	.customer-account-forgotpassword {
		.fieldset {
			.field {

				&.note {
					width: 74.2%;
					margin-left: auto;
					margin-right: auto;
					text-align: left;
				}

				&:not(.choice) > .label {
					float: none;
					text-align: left;
					padding: 0;
					display: block;
					width: 74.2%;
					margin: 0 auto;
				}
			}
		}
	}


}
