@include mobile(){

	.footer{
		.copyright, .bugs{
			display: block;
			margin: 20px 0 0;
		}
	}

	.page-footer {
		padding-top: 0;
		margin-top: 0;

		.footer.content {
			padding-top: 0;
			padding-left: 0;
			padding-right: 0;
			margin-top: 0;
			border-top: none;
			background-color: $footer-container-color;
			color: $c-text-primary-white;

			.footer-header {
				background-color: $c-background-primary;
				text-align: center;

				& > span {
					color: $text-heading-categories;
					font-weight: $text-weight-bold;
					font-size: 2rem;
				}

				img{
					display: block;
					border-bottom: 2px solid #ffffff;
				}
			}
			.footer-top {
				margin-top: 3rem;
				margin-left: 2rem;

				& > a {
					color: $c-text-primary-white;
					text-decoration: none;
					padding-top: 0.5rem;
					display: inline-block;
					font-weight: $text-weight-bold;
				}
				ul {
					float: right;
					margin-right: 2rem;

					& > li {
						display: inline;
						a {
							color: $social-links;
							text-decoration: none;
							font-size: 0;

							.fa-stack  {
								font-size: 20px !important;
								span {
									font-size: 0;
								}
							}
						}
					}
				}
			}
			.footer-mid {
				clear: both;
				padding-top: 1rem;
				margin-left: 2rem;
				margin-right: 2rem;
				margin-bottom: 5rem;

				& >	p {
					font-weight: $text-weight-light;
					a {
						color: $c-text-primary-white;
						text-decoration: none;
						font-weight: $text-weight-light;
					}
				}
			}
			.footer-bottom {
				text-align: center;

				ul {
					& > li {
							display: inline;

							span {
								font-weight: $text-weight-light;
							}

							a {
								color: $c-text-primary-white;
								text-decoration: none;
								font-weight: $text-weight-light;
							}
						}
					}
				}
			}
		}
	}

	@include desktop(){

	}
