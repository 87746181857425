/*  -----  !!!!!!!!!!!  -----  */
/*  -----  MATERIALIZE  -----  */
/*  -----  !!!!!!!!!!!  -----  */

/*  -----  FOOTER  -----  */
$footer-container-color: #1D1E35;
$footer-bg-color: #f0f0f0;

/*  -----  GLOBAL  -----  */

$small-screen-up: 768px;
$small-screen: 767px;

/*  -----  GRID  -----  */

$gutter-width: 0;

/*  -----  !!!!!  -----  */
/*  -----  THEME  -----  */
/*  -----  !!!!!  -----  */

/*  -----  GENERAL  -----  */

$c-body: #150036;
$c-body-bg: #f0f0f0;

$line-height: 1.42857143;

$font-family-base: 'Helvetica Neue', 'Open Sans', Helvetica, Arial, sans-serif;
$font-family-heading: "null";
$font-family-special: "null";

$text-product: #150036;              //is used
$text-category-breadcrumbs: #36315e; //is used
$text-product-breadcrumbs: #37305d;  //is used
$text-top-links: #25043d;            //is used
$text-heading-categories: #150037;   //is used
$text-active-category: #f25e00;      //is used
$c-text-primary-black: #333;         //is used
$c-text-primary-white: #fff;         //is used
$c-text-secondary-black: #333;
$c-text-secondary-white: #fff;
$c-text-required: #e02b27;

$form-border-color: #f25e00;

$text-weight-light: 300;
$text-weight-normal: 400;
$text-weight-bold: 700;

$quickbuy-background: #f95100;            //is used
$social-links: #49426f;                  //is used
$categories-background: #f0f7fa;         //is used
$categories-background-hover: #150037;  //is used

$c-background-primary: #fff;            //is used
$c-background-secondary: #f0f0f0;

$c-border-primary: #cccccc;
$c-border-secondary: #cccccc;


$c-nav-bg-primary: #f5f5f5;


$c-anchor: $text-heading-categories;
$c-anchor-hover: $text-active-category;
$c-anchor-active: $text-active-category;
$c-anchor-visited: $text-active-category;

$categories-grid-primary-border: #150037;       //is used
$categories-grid-secondary-border: #545363;     //is used

$c-action-primary-bg: #150037;
$c-action-primary-border: #150037;
$c-action-secondary-bg: #1979c3;
$c-action-secondary-border: #1979c3;

$c-action-primary-bg-hover: #f25e00;
$c-action-primary-border-hover: #f25e00;
$c-action-secondary-bg-hover: #006bb4;
$c-action-secondary-border-hover: #006bb4;

$c-action-primary-bg-active: #f25e00;
$c-action-primary-border-active: #f25e00;
$c-action-secondary-bg-active: #006bb4;
$c-action-secondary-border-active: #006bb4;

$c-action-primary-bg-focus: #f25e00;
$c-action-primary-border-focus: #f25e00;
$c-action-secondary-bg-focus: #006bb4;
$c-action-secondary-border-focus: #006bb4;

/*  -----  FORMS  -----  */

$c-button-bg: #eeeeee;
$c-button-border: #cccccc;
$c-button-text: #333333;

$c-button-bg-focus: #e2e2e2;
$c-button-border-focus: #cccccc;
$c-button-text-focus: #333333;

$c-button-bg-active: #e2e2e2;
$c-button-border-active: #cccccc;
$c-button-text-active: #333333;

$c-button-bg-hover: #e1e1e1;
$c-button-border-hover: #cccccc;
$c-button-text-hover: #333333;

$button-border-width: 1px;
$button-border-radius: 3px;


$c-form-bg-primary: #fff;
$c-form-border-primary: #c2c2c2;

$c-form-bg-secondary: #fff;
$c-form-border-secondary: #c2c2c2;

$c-form-placeholder: #c2c2c2;

/*  -----  HEADER  -----  */

$header-content-padding: 0 1.5em;
$header-logo-margin: 0 auto;

/*  -----  HOVERCSS  ----  */

$includeClasses: false;
