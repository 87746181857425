@include mobile(){

	.category-request {
		.form.customform {

			.request.btn.primary {
				background-color: $text-heading-categories;
				color: $c-text-primary-white;
				font-weight: $text-weight-bold;
				border-radius: 0;
				
				&:hover,
				&:focus,
				&:active {
					background-color: $text-active-category;
				}
			}
		}

		.sortby-links{
			display: none;
		}
	}

	.catalogsearch-result-index, .catalog-category-view {

		.modal-inner-wrap {
			max-width: 500px;

			.action.tocart.primary {
				margin-top: 1rem;
			}
		}

		.top-container {
			display: inline-block;

			.breadcrumbs-wrapper {
				margin: 2rem 0;


				.breadcrumbs {
					margin-top: 0;
					margin-bottom: 0;
					padding-left: 0;
					display: inline-block;
					font-style: italic;

					.items {
						.item {

							a, strong {
								color: $text-category-breadcrumbs;
								font-weight: $text-weight-light;
							}
						}
					}
				}

				.sortby-links {
					float: right;

					.sortby-label, a {
						font-size: 1.2rem;
						color: $text-category-breadcrumbs;
						text-decoration: none;
						font-weight: $text-weight-light;
					}

					a{
						&::before {
							content: "|";
							margin-left: 0.3rem;
							margin-right: 0.3rem;
							color: $text-category-breadcrumbs;
							font-weight: $text-weight-light;
						}

						&:first-child{
							&::before{
								display: none;
							}
						}
					}
				}
			}
		}

		.page-main {

			.page-title-wrapper {
				display: none;
			}

			.columns {
				.column.main {



					.products-grid {
						.product-items {
							.product-item {
								margin-bottom: 0;

								.product-item-info {
									.product-item-photo {
										.product-image-container {
											border: 1px solid $categories-grid-primary-border;
										}
									}

									.product-item-details {
										position: relative;
										.product-item-name {
											min-height: 45px;
											border-bottom: 1px solid $categories-grid-secondary-border;
											position: relative;
											margin: 0;
											max-width: 77%;

											.product-item-link {
												display: inline-block;
												color: $text-top-links;
												font-weight: $text-weight-bold;
											}
											.product-item-actions {
												float: right;
												position: absolute;
												top: 6px;
												right: -5px;
												margin: 0;

												.tocart {
													padding: 0;
													background-color: $c-background-primary;
													border: none;

													& > span {
														color: $quickbuy-background;
													}

													&:hover,
													&:focus,
													&active {
														& > span {
															color: darken($quickbuy-background, 10%);
														}
													}
												}
											}
										}
										.product-item-inner {
											position: absolute;
											right: 0;
											top: 22%;
											.tocart {
												border-radius: 24px;
												background: #ff5501;
												border: 1px solid #ff5501;
												padding: 12px 15px;
											}
										}
										.price-box.price-final_price {
											.price-including-tax{
												display: none;
											}

											.price-excluding-tax{
												&::before{
													font-size: 1rem;
												}
											}

											.special-price {
												.price-label {
													display: none;
												}
											}

											.old-price {
												display: none;
											}

											.price-container.price-final_price {
												.price-wrapper {
													.price {
														font-size: 1rem;
														font-weight: $text-weight-normal;
														color: $text-top-links;
													}
												}
											}
										}
									}
								}
							}
						}
					}

					.toolbar.toolbar-products {
						display: none;
						.toolbar-sorter.sorter, .toolbar-amount, .field.limiter {
							display: none;
						}
					}
				}
				.sidebar-main {
					margin-top: 2rem;

					.block-content.filter-content {
						.filter-options {

							.items {
								.item {
									a {
										color: $text-heading-categories;
										font-size: 1.8rem;
										text-decoration: none;
										display: block;

										&:hover,
										&:focus,
										&:active {
											color: $text-active-category;
										}
									}
								}
								.active.item {
									a {
										color: $text-active-category;
										font-weight: $text-weight-bold;
									}
								}
							}
						}
					}
					.block.filter {
						.filter-options {
							.filter-options-title:first-child  {
								display: none;
							}
						}
					}
				}
			}
		}
	}


	.filter-current{
		.item{
			margin-bottom: 10px;
		}
		.filter-label{
			display: block;
			font-weight: 700;
		}
	}
	.filter-options{
		.filter-options-content{
			margin: 0 0 25px;

			.item{
				margin-bottom: 3px;
			}
		}
		.count{
			color: #7d7d7d;
		}
	}

	.page-products{
		.product-item-info{
			margin: 0 auto;

			.product-item-details{
				text-align: left;
			}
		}

		.product-item-actions{
			display: block;

			.actions-secondary > .action.tocompare{
				display: inline-block;
			}
		}
	}

	.sorter {
		& > *{
			float: left;
		}

		.sorter-label{
			line-height: 2.5;
		}
	}

	.catalogsearch-result-index {

		.breadcrumbs {
			text-align: center;
		}

		.columns {
			.sidebar-wrapper {
				max-width: 70%;
				margin-left: auto;
				margin-right: auto;

				.items {
					.item {
						margin-bottom: 1rem;
					}
				}
			}
		}
	}
	.stock.unavailable {
		display: none;
	}
}

@include desktop(){

	.category-request {
		.form.customform {

			.name {
				width: 50%;
				float: left;
				padding-right: 2rem;
			}

			.email {
				width: 50%;
				float: left;
			}

			.phone-number {
				clear: both;
			}
		}
	}

	.catalog-category-view {
		.breadcrumbs-wrapper {
			.sortby-links {
				padding-right: 1em;
			}
		}
	}

	.catalogsearch-result-index {

		.breadcrumbs {
			text-align: left;
		}

		.columns {
			.sidebar-wrapper {
				max-width: none;
				margin-left: 0;
				margin-right: 0;

			}
		}
	}
	.catalogsearch-result-index, .catalog-category-view{
		.page-main .columns .column.main {
			.products-grid{
				.product-items {
					.product-item {
						margin-left: calc((100% - 3 * 33.333%) / 3);
						width: 33.333%;
						padding-left: 0;
	
						.product-item-info{
							width: 95%;
							margin-left: 5%;
	
							.product-item-details{
								text-align: left;
								position: relative;
								.product-item-name{
									max-width: 80%;
									.product-item-actions {
										margin-top: 1rem !important;
									}
								}
								.product-item-inner {
									position: absolute;
									right: 0;
									top: 6%;
									.tocart {
										border-radius: 24px;
										background: #ff5501;
										border: 1px solid #ff5501;
										padding: 12px 15px;
									}
								}
								.price-box{
									min-height: 30px;
								}
							}
						}
					}
					.product-item:nth-child(3n + 1) {
						margin-left: 0;
					}
				}
			}
		}
	}
	

	@media(min-width: 1300px) {
		.catalog-category-view {
			.breadcrumbs-wrapper {
				.sortby-links {
					padding-right: 0;
				}
			}
		}
	}

	.stock.unavailable {
		display: none;
	}
}
