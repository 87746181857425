@include mobile(){
	.checkout-cart-index{

		#shopping-cart-table {
			.cart.item {

				.col.item {
					.product-item-name {
						a {
							color: $text-product;
						}
					}
				}

				.col.qty {
					.control.qty {
						.input-text.qty {
							border: 1px solid $text-heading-categories;
						}
					}
				}

				.col.price, .col.subtotal {
					& > span {
						font-size: 1rem;
					}

				}
			}
		}

		.page-main{
			padding-left: 0;
			padding-right: 0;

					.columns {
						.column.main {
							.cart-container {
								.form-cart {
									.actions {
										a, button {
											max-width: 300px;
											width: 100%;
											display: block;
											background-color: $text-heading-categories;
											color: $c-text-primary-white;

											&:hover,
											&:focus,
											&:active {
												background-color: $text-active-category;
											}
										}
									}
								}
							}
						}
					}
				}

		.page-title-wrapper{
			padding-left: 15px;
			padding-right: 15px;

			.page-title {
				color: $text-product;
			}
		}
	}

	.column.main{
		.cart-summary{
			background: #f5f5f5;
			margin-bottom: 25px;
			padding: 1px 0 25px;

			& > .title{
				font-size: 2.4rem;
				display: none;
				font-weight: 300;
				margin: 12px 0;
			}

			.block{
				.price{
					font-weight: 700;
				}

				.field{
					margin: 0 0 16px;

					&.note{
						display: none;
					}
				}

				.actions-toolbar > .primary{
					text-align: left;

					.action.primary{
						width: auto
					}
				}

				.title {
					&:after{
						top: -2px;
					}

					strong{
						font-size: 1.4rem;
						font-weight: 600;
					}
				}

				.item-options{
					margin: 0 0 16px;

					.field .radio{
						float: left;

						& + .label{
							display: block;
							margin: 0;
							overflow: hidden;
						}
					}
				}

				.checkout-methods-items{
					margin: 20px 0 0;
					padding: 0 15px;
					text-align: center;

					.action.primary.checkout{
						width: 100%;
					}

					.item{
						margin-bottom: 25px;

						&:last-child{
							margin-bottom: 0;
						}
					}
				}
			}

			& > div > div{
				padding-left: 10px;
				padding-right: 10px;
			}

			.message{
				padding-left: 20px;

				& > *:first-child:before{
					display: none;
				}
			}
		}

		.cart-totals{
			tbody, tfoot{
				.mark{
					text-align: left;
				}
			}
		}

		.multicheckout .block-title,
		.multicheckout .block-content .title{
			border-bottom: 1px solid #d1d1d1;
			padding-bottom: 10px;

			strong{
				font-weight: 400;

				span{
					color: #a6a6a6;
				}
			}
		}

		.cart.table-wrapper {
			.actions-toolbar > .action{
				font-size: 1.1em;
			}

			.gift-content{
				border-bottom: 1px solid #d1d1d1;
				margin-bottom: 20px;
			}
		}

		.abs-shopping-cart,
		.block-cart-failed,
		.cart-container .form-cart{
			.action.continue::before,
			.action.update::before{
				line-height: 24px;
			}
		}

		.cart-container .form-cart{
			.cart.actions{
				button, a{
					font-size: 1.1em;
				}
			}
		}
	}

	.block-minicart{
		.block-content{
			a{
				color: #150037;
			}
		}
	}
}

@include desktop(){
	.checkout-cart-index{
		.page-main{
					.columns {
						.column.main {
							.cart-container {
								padding: 1rem;

								.form-cart {
									.actions {
										font-size: 0;

										@media (min-width: 768px) {
											a, button {
											  font-size: 1.1rem;
												max-width: 51%;
												text-align: center;
												margin-left: 0;
												margin-bottom: 10px;
											}
										}

										@media (min-width: 1200px) {
											text-align: left;
											a, button {
												font-size: 1.1rem;
												max-width: 31%;
												display: inline;
												margin-right: 1rem;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
