@include mobile() {
	body {
		color: $c-body;
		background-color: $c-body-bg;
		font-family: $font-family-base;
		font-style: normal;
		font-weight: 400;
		line-height: $line-height;
		font-size: 1rem;
	}

	p {
		margin-top: 0rem;
		margin-bottom: 1rem;
	}

	h1 {
		font-weight: 300;
		line-height: 1.1;
		font-size: 2.6rem;
		margin-top: 0rem;
		margin-bottom: 2rem;
	}
	h2 {
		font-weight: 300;
		line-height: 1.1;
		font-size: 2.6rem;
		margin-top: 2.5rem;
		margin-bottom: 2rem;
	}
	h3 {
		font-weight: 600;
		line-height: 1.1;
		font-size: 1.8rem;
		margin-top: 2rem;
		margin-bottom: 1rem;
	}
	h4 {
		font-weight: 700;
		line-height: 1.1;
		font-size: 1.4rem;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
	h5 {
		font-weight: 700;
		line-height: 1.1;
		font-size: 1.2rem;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
	h6 {
		font-weight: 700;
		line-height: 1.1;
		font-size: 1rem;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
	h1, h2, h3, h4, h5, h6{
		small, .small{
			color: $c-text-primary-black;
			font-family: $font-family-base;
			font-style: normal;
			font-weight: 400;
			line-height: 1;
		}
	}

	a, .alink {
		@include link();
	}

	ul,
	ol {
		margin-top: 0rem;
		margin-bottom: 2.5rem;
	}
	ul > li,
	ol > li {
		margin-top: 0rem;
		margin-bottom: 1rem;
	}
	ul ul,
	ol ul,
	ul ol,
	ol ol {
		margin-bottom: 0;
	}
	dl {
		margin-bottom: 20px;
		margin-top: 0;
	}
	dt {
		font-weight: 700;
		margin-bottom: 5px;
		margin-top: 0;
	}
	dd {
		margin-bottom: 10px;
		margin-top: 0;
		margin-left: 0;
	}

	blockquote {
		border-left: 0 solid $c-border-primary;
		margin: 0 0 20px 40px;
		padding: 0;
		color: $c-text-primary-black;
		font-family: $font-family-base;
		font-style: italic;
		font-weight: 400;
		line-height: $line-height;
		font-size: 1.4rem;
	}

	table {
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		max-width: 100%;
		th{
			text-align: left;
		}

		tbody, tfoot {
			tr {
				th,td {
					vertical-align: top;
					padding: 11px 10px;
				}
			}
		}

		thead {
			tr {
				th,td {
					vertical-align: bottom;
					padding: 11px 10px;
				}
			}
		}
	}

	.action-primary:focus{
		background: $c-action-primary-bg-focus;
		border: 1px solid $c-action-primary-border-focus;
		color: $c-text-primary-white;
	}
	.action-primary:active {
		background: $c-action-primary-bg-active;
		border: 1px solid $c-action-primary-border-active;
		color: $c-text-primary-white;
	}
	.action-primary:hover {
		background: $c-action-primary-bg-hover;
		border: 1px solid $c-action-primary-border-hover;
		color: $c-text-primary-white;
	}
	.action-primary.disabled,
	.action-primary[disabled],
	fieldset[disabled] .action-primary {
		opacity: 0.5;
		cursor: default;
		pointer-events: none;
	}
	.message.info,
	.message.error,
	.message.warning,
	.message.notice,
	.message.success{
		a{
			color: $c-anchor;
			&:hover{
				color: $c-anchor-hover;
			}
			&:active{
				color: $c-anchor-active;
			}
		}
		&  > *:first-child:before {
			-webkit-font-smoothing: antialiased;
			font-size: 24px;
			line-height: 24px;
			color: #c07600;
			content: '\e602';
			font-family: 'icons-blank-theme';
			margin: -12px 0 0;
			vertical-align: middle;
			display: inline-block;
			font-weight: normal;
			overflow: hidden;
			speak: none;
			left: 0;
			top: 22px;
			width: 45px;
			position: absolute;
			text-align: center;
		}
	}
	.message.info {
		margin: 0 0 10px;
		padding: 12px 20px 12px 25px;
		display: block;
		font-size: 1.3rem;
		background: #fdf0d5;
		color: #6f4400;
		padding-left: 45px;
		position: relative;
	}
	.message.error {
		margin: 0 0 10px;
		padding: 12px 20px 12px 25px;
		display: block;
		font-size: 1.3rem;
		background: #fae5e5;
		color: #e02b27;
		padding-left: 45px;
		position: relative;
	}

	.message.warning {
		margin: 0 0 10px;
		padding: 12px 20px 12px 25px;
		display: block;
		font-size: 1.3rem;
		background: #fdf0d5;
		color: #6f4400;
		padding-left: 45px;
		position: relative;
	}

	.message.notice {
		margin: 0 0 10px;
		padding: 12px 20px 12px 25px;
		display: block;
		font-size: 1.3rem;
		background: #fdf0d5;
		color: #6f4400;
		padding-left: 45px;
		position: relative;
	}

	.message.success {
		margin: 0 0 10px;
		padding: 12px 20px 12px 25px;
		display: block;
		font-size: 1.3rem;
		background: #e5efe5;
		color: #006400;
		padding-left: 45px;
		position: relative;
	}

	.nav-toggle {
		display: inline-block;
		text-decoration: none;
		cursor: pointer;
		display: block;
		font-size: 0;
		left: 15px;
		position: absolute;
		top: 15px;
		z-index: 14;

		&:before{
			-webkit-font-smoothing: antialiased;
			font-size: 28px;
			line-height: inherit;
			color: #8f8f8f;
			content: '\e609';
			font-family: 'icons-blank-theme';
			vertical-align: middle;
			display: inline-block;
			font-weight: normal;
			overflow: hidden;
			speak: none;
			text-align: center;
		}

		&:hover:before{
			color: $c-text-primary-black;
		}
	}

	.tooltip.wrapper {
		position: relative;

		.tooltip.content{
			@include tooltip();
		}
	}

	.load.indicator{
		background-color: rgba(255, 255, 255, 0.7);
		z-index: 9999;
		bottom: 0;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		position: absolute;

		&:before{
			background: transparent url('../images/loader-2.gif') no-repeat 50% 50%;
			border-radius: 5px;
			height: 160px;
			width: 160px;
			bottom: 0;
			box-sizing: border-box;
			content: '';
			left: 0;
			margin: auto;
			position: absolute;
			right: 0;
			top: 0;
		}

		& > span{
			display: none;
		}

		.loading-mask {
			bottom: 0;
			left: 0;
			margin: auto;
			position: fixed;
			right: 0;
			top: 0;
			z-index: 100;
			background: rgba(255, 255, 255, 0.5);

			.loader{
				& > p{
					display: none;
				}

				& > img{
					bottom: 0;
					left: 0;
					margin: auto;
					position: fixed;
					right: 0;
					top: 0;
					z-index: 100;
				}
			}
		}
	}

	.action.primary{
		background-image: none;
		background: $c-action-primary-bg;
		border: 1px solid $c-action-primary-border;
		color: $c-text-primary-white;
		cursor: pointer;
		display: inline-block;
		font-family: $font-family-base;
		font-weight: 600;
		padding: 7px 15px;
		font-size: 1.4rem;
		box-sizing: border-box;
		vertical-align: middle;
		border-radius: 0;

		&:hover{
			background: $c-action-primary-bg-hover;
			border: 1px solid $c-action-primary-border-hover;
			color: $c-text-primary-white;
		}

		&:focus{
			background: $c-action-primary-bg-focus;
			border: 1px solid $c-action-primary-border-focus;
			color: $c-text-primary-white;
		}

		&:active{
			background: $c-action-primary-bg-active;
			border: 1px solid $c-action-primary-border-active;
			color: $c-text-primary-white;
		}

		.disabled, &[disabled]{
			opacity: 0.5;
			cursor: default;
			pointer-events: none;
		}
	}
	fieldset[disabled] .action.primary {
		opacity: 0.5;
		cursor: default;
		pointer-events: none;
	}

	.product.data.items > .item.title{
		& > .switch {
			border-top: 1px solid $c-border-primary;
			height: 40px;
			padding: 1px 10px 1px 10px;
			font-weight: 600;
			line-height: 40px;
			font-size: 1.4rem;
			color: $c-text-primary-black;
			text-decoration: none;

			&:visited, &:active, &:active{
				color: $c-text-primary-black;
				text-decoration: none;
			}
		}
	}

	.pages{
		a.page{
			color: $c-anchor;
			display: inline-block;
			padding: 0 5px;
			text-decoration: none;

			&:visited{
				color: $c-anchor-visited;
			}
			&:hover{
				color: $c-anchor-hover;
				text-decoration: none;
			}
			&:active{
				color: $c-anchor-active;
			}
		}
		strong.page{
			background: darken($c-background-primary, 11%);
			font-size: 1.2rem;
			font-size: 12px;
			letter-spacing: normal;
			line-height: 30px;
			color: $c-text-primary-black;
			display: inline-block;
			font-weight: 700;
			padding: 0 5px;
		}
		.action{
			background: #f0f0f0;
			border: 1px solid #cccccc;
			color: #lighten;
			display: inline-block;
			padding: 0;
			text-decoration: none;

			&:visited{
				color: #lighten($c-text-primary-black, 37%);
			}
			&:hover{
				background: darken($c-background-primary, 12%);
				color: #lighten($c-text-primary-black, 37%);
				text-decoration: none;
			}
			&:active{
				color: #lighten($c-text-primary-black, 37%);
			}
		}
		.action.next:visited:before,
		.action.next:active:before,
		.action.next:hover:before{
			color: lighten($c-text-primary-black, 39%);
		}

		.pages .action.previous:hover:before,
		.pages .action.previous:active:before{
			color: lighten($c-text-primary-black, 39%);
		}
	}
	.breadcrumbs{
		.items{
			font-size: 1.2rem;
			color: $text-product;
			margin: 0;
			padding: 0;
			list-style: none none;
		}
		a{
			color: $c-text-primary-black;
			text-decoration: none;

			&:visited, &:hover, &:active{
				@extend a;
			}
		}
		.item:not(:last-child)::after{
			font-size: 25px;
			line-height: 25px;
		}
	}
	.ui-dialog.popup .action.close{
		&:before{
			-webkit-font-smoothing: antialiased;
			font-size: 16px;
			line-height: 16px;
			color: lighten($c-text-primary-black, 37%);
			content: '\e616';
			font-family: 'icons-blank-theme';
			margin: 0;
			vertical-align: top;
			display: inline-block;
			font-weight: normal;
			overflow: hidden;
			speak: none;
			text-align: center;
		}
		&:hover:before, &:active:before{
			color: lighten($c-text-primary-black, 25%);
		}
		:hover, :focus, :active{
			background: none;
			border: none;
		}
	}

	.popup-pointer{
		&:before{
			left: 2px;
			top: 2px;
			border: solid 6px;
			border-color: transparent transparent $c-text-primary-white transparent;
			z-index: 99;
		}
		&:after{
			left: 1px;
			top: 0px;
			border: solid 7px;
			border-color: transparent transparent #aeaeae transparent;
			z-index: 98;
		}
	}

	.modal-popup .modal-title{
		border-bottom: 1px solid darken($c-border-primary, 4%);
		font-weight: 300;
		padding-bottom: 10px;
		font-size: 26px;
		margin-bottom: 0;
		min-height: 1em;
		word-wrap: break-word;
	}

	.paypal-review-discount{
		border-bottom: 1px solid $c-border-primary;
		margin: 0 0 40px;
		padding: 10px 0 0;
	}

	.sidebar .product-items{
		.counter{
			color: #lighten($c-text-primary-black, 37%);
			font-size: 12px;
			white-space: nowrap;
		}
	}

	.block-search{
		margin-top: 1.4em;

		.label{
			&:before{
				-webkit-font-smoothing: antialiased;
				font-size: 22px;
				line-height: 28px;
				color: lighten($c-text-primary-black, 37%);
				content: '\e615';
				font-family: 'icons-blank-theme';
				margin: 0 10px 0 0;
				vertical-align: middle;
				display: inline-block;
				font-weight: normal;
				overflow: hidden;
				speak: none;
				text-align: center;
			}

			&:hover:before, &:active:before{
				color: $c-text-primary-black;
			}
		}
		.control{
			border-top: 1px solid $c-border-primary;
			clear: both;
			margin: 0 -15px -1px;
			padding: 0 15px;
		}

		input{
			left: -300%;
			margin: 15px 0;
			position: absolute;
		}
	}

	.search-autocomplete{
		display: none;
		margin-top: -15px;
		overflow: hidden;
		position: absolute;
		z-index: 3;

		ul{
			margin: 0;
			padding: 0;
			list-style: none none;

			&:not(:empty){
				background: $c-background-primary;
				border: 1px solid darken(#fff, 24%);
				border-top: 0;
			}

			li{
				border-top: 1px solid #e5e5e5;
				cursor: pointer;
				margin: 0;
				padding: 5px 40px 5px 10px;
				position: relative;
				text-align: left;
				white-space: normal;

				&:first-child{
					border-top: none;
				}

				&:hover, &.selected{
					background: #e8e8e8;
				}

				.amount{
					color: #999999;
					position: absolute;
					right: 7px;
					top: 5px;
				}
			}
		}
	}


	.block.newsletter{
		margin-bottom: 40px;

		.form.subscribe{
			display: table;
			width: 100%;
		}
		.fieldset {
			display: table-cell;
			margin: 0;
			padding: 0;
			vertical-align: top;
		}
		.field{
			margin: 0;

			.control{
				display: inline-block;
				text-decoration: none;
				display: block;

				&:before{
					-webkit-font-smoothing: antialiased;
					font-size: 35px;
					line-height: 33px;
					color: darken($c-text-primary-white, 24%);
					content: '\e61d';
					font-family: 'icons-blank-theme';
					vertical-align: middle;
					display: inline-block;
					font-weight: normal;
					overflow: hidden;
					speak: none;
					text-align: center;
					position: absolute;
				}
			}
		}

		input{
			padding-left: 35px;
		}

		.title{
			display: none;
		}

		.actions{
			display: table-cell;
			vertical-align: top;
			width: 1%;
		}
	}

	.table-wrapper{
		.table:not(.cart):not(.totals):not(.table-comparison){
			&  > tbody > tr{
				clear: both;
			}
		}
	}

	.page-title-wrapper{
		margin-top: 2em;
		font-size: 2em;
	}

	.columns{
		.column.main{
			@media(max-width: 1300px){
				padding-left: 1em;
				padding-right: 1em;
			}
		}
	}

	.page-wrapper{
		max-width: 1280px;
		margin: 0 auto;
		background-color: #fff;
	}
}

@include desktop() {

	p {
		text-align: left;
		margin-bottom: 1em;
		@include bp("min-width", 991px) {
			font-size: 1.2em;
		}
	}

	.page-layout-1column .column.main{
		margin-top: 2em;
		padding-left: 15px;
		padding-right: 15px;
	}
}
