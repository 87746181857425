@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue/HelveticaNeue.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue/HelveticaNeueBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue/HelveticaNeueLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
