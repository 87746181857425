@include mobile(){
	button {
		background-image: none;
		background: $c-button-bg;
		border: 1px solid $c-button-border;
		border-radius: 3px;
		color: $c-button-text;
		cursor: pointer;
		display: inline-block;
		font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
		font-weight: 600;
		margin: 0;
		padding: 7px 15px;
		font-size: 1.4rem;
		line-height: 1.6rem;
		box-sizing: border-box;
		vertical-align: middle;

		&:focus{
			background: $c-button-bg-focus;
			border: 1px solid $c-button-border-focus;
			color: $c-button-text-focus;
		}

		&:active{
			background: $c-button-bg-active;
			border: 1px solid $c-button-border-active;
			color: $c-button-text-active;
		}

		&:hover{
			background: $c-button-bg-hover;
			border: 1px solid $c-button-border-hover;
			color: $c-button-text-hover;
		}

		.disabled, &[disabled]{
			opacity: 0.5;
			cursor: default;
			pointer-events: none;
		}

		&::-moz-focus-inner{
			border: 0;
			padding: 0;
		}
	}
	fieldset[disabled] button {
		opacity: 0.5;
		cursor: default;
		pointer-events: none;
	}
	input[type="text"],
	input[type="password"],
	input[type="url"],
	input[type="tel"],
	input[type="search"],
	input[type="number"],
	input[type="datetime"],
	input[type="email"] {
		font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
		font-size: 14px;
		height: 40px;
		line-height: $line-height;
		padding: 0 9px;
		vertical-align: baseline;
		width: 100%;
		box-sizing: border-box;

		&:disabled{
			opacity: 0.5;
		}

		&::-moz-placeholder, &::-webkit-input-placeholder, &::-ms-input-placeholder{
			color: $c-form-placeholder;
		}
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}
	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type="search"] {
		-webkit-appearance: none;
	}
	input[type="search"]::-webkit-search-cancel-button,
	input[type="search"]::-webkit-search-decoration,
	input[type="search"]::-webkit-search-results-button,
	input[type="search"]::-webkit-search-results-decoration {
		-webkit-appearance: none;
	}
	select {
		background: $c-form-bg-primary;
		background-clip: padding-box;
		border: 1px solid $c-form-border-primary;
		border-radius: 1px;
		font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
		font-size: 14px;
		height: 32px;
		line-height: $line-height;
		padding: 5px 10px 4px;
		vertical-align: baseline;
		width: 100%;
		box-sizing: border-box;

		&:disabled{
			opacity: 0.5;
		}

		&[multiple="multiple"]{
			height: auto;
			background-image: none;
		}
	}
	textarea {
		background: $c-form-bg-primary;
		background-clip: padding-box;
		border: 1px solid $c-form-border-primary;
		border-radius: 1px;
		font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
		font-size: 14px;
		height: auto;
		line-height: $line-height;
		margin: 0;
		padding: 10px;
		vertical-align: baseline;
		width: 100%;
		box-sizing: border-box;
		resize: vertical;

		&:disabled{
			opacity: 0.5;
		}

		&::-moz-placeholder, &::-webkit-input-placeholder, &::-ms-input-placeholder{
			color: $c-form-placeholder;
		}
	}
	input[type="checkbox"], input[type="radio"] {
		margin: 2px 5px 0 0;

		&:disabled{
			opacity: 0.5;
		}
	}

	.fieldset,
	.fieldset > .fields{
		& > .field .addon .addbefore, & > .field .addon .addafter{
			background: $c-form-bg-primary;
			background-clip: padding-box;
			border: 1px solid $c-form-border-primary;
			border-radius: 1px;
			font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
			font-size: 14px;
			height: 32px;
			line-height: $line-height;
			padding: 0 9px;
			vertical-align: baseline;
			width: 100%;
			box-sizing: border-box;
			-ms-flex-order: 3;
			-webkit-order: 3;
			order: 3;
			display: inline-block;
			vertical-align: middle;
			white-space: nowrap;
			width: auto;
		}
		& > .field .addon .addbefore::-moz-placeholder, 
		& > .field .addon .addafter::-moz-placeholder, 
		& > .field .addon .addbefore::-webkit-input-placeholder, 
		& > .field .addon .addafter::-webkit-input-placeholder, 
		& > .field .addon .addbefore:-ms-input-placeholder, 
		& > .field .addon .addafter:-ms-input-placeholder{
			color: $c-form-placeholder;
		}

		& > .field.required > .label:after, 
		& .field._required > .label:after{
			content: '*';
			color: #e02b27;
			font-size: 1.2rem;
			margin: 0 0 0 5px;
		}
	}

	.fieldset > .field:not(.choice) > .label{
		width: 100%;
		float: none;
		text-align: left;
	}

	select {
		display: block;
		// background: $c-form-bg-primary url('../images/select-bg.svg') no-repeat 100% 45%;
		// background-size: 30px 60px;
		border: 1px solid #cccccc;
		height: 32px;
		padding-right: 25px;
		text-indent: .01em;
		text-overflow: '';
	}

	.input-field label.active:not(.label-icon){
		transform: translateY(-175%);
	}

	.input-field {
		.select-wrapper {

			.select-dropdown:disabled {
				display: none;
			}

			.caret.disabled {
				display: none;
			}

			.dropdown-content {
				display: block;
				max-height: 0;
				width: 100%;
				top: 0;
				opacity: 1;
				transition: 0.25s max-height ease-out, 0.1s opacity ease-out;

				&.active {
					opacity: 1;					
					height: auto;
					max-height: 300px;	
					transition: 0.25s max-height ease-in, 0.5s opacity ease-in;					
				}
			}

			&.active .dropdown-content {
				opacity: 1;					
				height: auto;
				max-height: 300px;	
				transition: 0.25s max-height ease-in, 0.5s opacity ease-in;					
			}

			select {
				display: none;
			}
		}
	}
}

@include desktop(){

}