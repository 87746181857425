@include mobile() {
  .cms-index-index,
  .cms-home  {
    .all-categories {
      font-size: 0;
      text-align: center;

      .category-wrapper {
        background-color: $categories-background;
        display: block;
        max-width: 51%;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        margin-bottom: 1rem;

          &:hover {
              .category-link {
                .category-name {
                  background-color: $categories-background-hover;
                  color: #fff;
                }
              }
          }

          .category-link {
            font-size: 1rem;
            text-decoration: none;

            .category-name {
              border-bottom: none;
              font-size: 1.2em;
              color: $text-heading-categories;
              font-weight: $text-weight-bold;
              padding: 1rem;
              margin-bottom: 0;
            }

            img {
              max-width: 350px;
              width: 100%;
            }
          }
        }
      }
    }

    .cms-index-index {
      .header.content {
        margin-bottom: 3rem !important;

        .minicart-wrapper,
        .block-search,
        .panel.wrapper,
        .panel.header {
          display: none;
        }
      }
    }

    .cms-home {
      .breadcrumbs {
        display: none;
      }
    }
}

@include desktop() {
  .cms-index-index,
  .cms-home  {
    .all-categories {

      .category-wrapper {
          display: inline-block;
          max-width: 33.33%;
          margin-right: 1rem;
          margin-bottom: 1rem;
          text-align: left;

          &:hover {
              .category-link {
                .category-name {
                  background-color: $categories-background-hover;
                  color: #fff;
                }
              }
          }

          .category-link {
            font-size: 1rem;
            text-decoration: none;

            .category-name {
              border-bottom: none;
              font-size: 1.2em;
              color: $text-heading-categories;
              font-weight: $text-weight-bold;
              padding: 1rem;
              margin-bottom: 0;
            }

            img {
              max-width: 350px;
            }
          }
        }
      }
    }
  }
